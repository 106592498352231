/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
textarea {
  margin: 0;
  padding: 0;
  border: 0;
  /* font-size: 100%; */
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  /* font: inherit; */
  vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}
/* body {
	line-height: 1;
	overflow: hidden;
	height: 100vh;
	width: 100vw;
} */
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button,
input {
  font-family: "Roboto", sans-serif;
}

/* Native Text style */
span {
  display: inline;
  line-height: 140%;
}
span * {
  display: inline;
}
*:focus {
  outline: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  /* -webkit-text-fill-color:inherit !important;
    color: inherit !important; */
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/* A little bit of a hacky fix for autoFill bug: https://github.com/facebook/react/issues/1159 */
@keyframes onAutoFillStart {
  from {
    /**/
  }
  to {
    /**/
  }
}
@keyframes onAutoFillCancel {
  from {
    /**/
  }
  to {
    /**/
  }
}
input:-webkit-autofill {
  animation-name: onAutoFillStart;
}
input:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}

.ace_editor * {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace !important;
  font-size: 15px !important;
  direction: ltr !important;
  text-align: left !important;
}
